export const CustomerPortalIcon = ({color}: {color: string}) => (
  <svg
    aria-hidden="true"
    className="svg-inline--fa fa-phone-laptop fa-w-20"
    data-icon="phone-laptop"
    data-prefix="fad"
    focusable="false"
    height="100%"
    role="img"
    viewBox="0 0 640 512"
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g className={`text-${color}-500 fa-group`}>
      <path
        className="fa-secondary"
        d="M128 64h320v32h64V48a48.1 48.1 0 0 0-47.91-48H111.91A48.1 48.1 0 0 0 64 48v240H16a16 16 0 0 0-16 16v16a64.14 64.14 0 0 0 63.91 64H352v-96H128z"
        fill="currentColor"
        key="secondary"
        opacity="0.4"
      />
      <path
        className="fa-primary"
        d="M604 128H420a36 36 0 0 0-36 36v312a36 36 0 0 0 36 36h184a36 36 0 0 0 36-36V164a36 36 0 0 0-36-36zm-28 320H448V192h128z"
        fill="currentColor"
        key="primary"
      />
    </g>
  </svg>
);
//   <svg
//     aria-hidden="true"
//     focusable="false"
//     data-prefix="fad"
//     data-icon="laptop"
//     className="svg-inline--fa fa-laptop fa-w-20"
//     role="img"
//     xmlns="http://www.w3.org/2000/svg"
//     viewBox="0 0 640 512"
//   >
//     <g className={`text-${color}-500 fa-group`}>
//       <path
//         className="fa-secondary"
//         fill="currentColor"
//         d="M528 0H112a48.14 48.14 0 0 0-48 48v336h512V48a48.14 48.14 0 0 0-48-48zm-16 320H128V64h384z"
//         opacity="0.4"
//       ></path>
//       <path
//         className="fa-primary"
//         fill="currentColor"
//         d="M512 64H128v256h384zm112 352H381.54c-.74 19.81-14.71 32-32.74 32H288c-18.69 0-33-17.47-32.77-32H16a16 16 0 0 0-16 16v16a64.19 64.19 0 0 0 64 64h512a64.19 64.19 0 0 0 64-64v-16a16 16 0 0 0-16-16z"
//       ></path>
//     </g>
//   </svg>
// );
